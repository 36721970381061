@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: black;
}

html * {
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
}

h1 {
  color: white;
  mix-blend-mode: difference;
}

p {
  color: white;
  font-size: 18px;
  line-height: 1.7;
  text-align: left;
  max-width: 900px;
  border-radius: 2px;
  margin: 0;
  text-rendering: optimizeLegibility;
}

a {
  text-decoration: none;
  color: #5B5B5B;
  transition: color 275ms ease-in-out;
  pointer-events: auto;
  cursor: pointer;
}

a:hover {
  color: black;
}

a > svg > path {
  fill: #5B5B5B;
  transition: fill 275ms ease;
}

a:hover > svg > path, a:hover > svg > rect {
  fill: black;
}

.landingSection {
  height: 100vh;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.centerVertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.centerHorizontally {
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#logo {
  position: fixed;
  top: calc(18px + 0.5vw);
  right: calc(19px + 0.5vw);
  width: 80px;
  transition: left 400ms;
  z-index: 5;
}

#logo.active {
  right: calc(19px + 0.5vw);
}

#logo > g > path, #logo > g > g > rect, #logo > g > rect {
  fill: white;
  transition: fill 300ms;
}

#logo.active > g > path, #logo.active > g > g > rect, #logo.active > g > rect {
  fill: black;
}

#menuIcon {
  position: fixed;
  left: calc(5px + 0.5vw);
  top: calc(5px + 0.5vw);
  z-index: 5;
}

@media only screen and (max-width: 930px) {
  #logo {
    width: 70px;
    top: calc(22px + 0.5vw);
  }
}

.menu {
  visibility: hidden;
  z-index: 1;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255,255,255,0.95);
  transition: opacity 300ms, visibility 300ms;
  transition-timing-function: ease-in-out;
}

.menu.active {
  opacity: 1;
  visibility: visible;
}

#menuLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  height: calc(100% - 125px);
  bottom: calc(125px + 10px + 0.5vh);
  left: calc(25px + 5vw);
}

#menuLinks > a > h1 {
  overflow: hidden;
  background: linear-gradient(to right, black, black 50%, #5B5B5B 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  mix-blend-mode: normal;
}

#menuLinks > a:hover > h1 {
  background-position: 0 100%;
  mix-blend-mode: normal;
}

.menuLink {
  font-size: calc(25px + 0.5vw);
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}

#mobileContactInfo {
  position: absolute;
  text-align: left;
  bottom: 125px;
  left: calc(25px + 5vw);
}

.contactInfo {
  font-size: 20px;
  margin-top: -10px;
}

#contactIcons {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
}

#contactIcons > a {
  margin-right: 10px;
  width: 25px;
}

#imdbLogo {
  width: 40px !important;
  margin-top: -3px;
}

#vimeoLogo {
  margin-top: 1px;
}

#videosSection {
  background-color: #141414;

  width: 100%;

  padding-top: 15px;
  padding-bottom: 15px;

  text-align: center;
  display: flex;
  justify-content: center;
}

#videoImages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  flex-wrap: wrap;
}
#modalContainer {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 0, 0, 0.7);

  visibility: hidden;
  opacity: 0;
  transition: visibility 200ms, opacity 200ms;
  transition-timing-function: ease-in-out;
  pointer-events: none;
}

#modalContainer.active {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  cursor: auto;
}

#modalBox {
  width: 90vw;
  max-width: 1000px;
}

.LazyLoad {
  width: 100%;
  height: 100%;
}

.videoThumbnail {
  border-radius: 1px;
  width: calc(33vw - 14px);
  height: calc(30vh - 14px - (30px/3));
  min-height: 200px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23);
  object-fit: cover;

  color: rgba(0, 0, 0, 0) !important;
  background-image: url('./assets/loader.svg');
  background-position: 50% 50%;
  background-size: 35% 35%;
  background-repeat: no-repeat;
}

.logoContainer {
  position: absolute;
  width: calc(33vw - 14px);
  height: calc(30vh - 14px - (30px/3));
  min-height: 200px;
  pointer-events: none;
}

.mobileHeader {
  display: none;
}

#desktopCategories {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80vw;
}

.desktopCategory {
  color: #5B5B5B;
  overflow: hidden;
  background: linear-gradient(to right, white, white 50%, #5B5B5B 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
}

.desktopCategory:hover {
  background-position: 0 100%;
}

@media only screen and (max-width: 1200px) {
  .videoThumbnail, .logoContainer {
    width: calc(49vw - 14px);
    min-height: 200px;
  }
  #desktopCategories {
    justify-content: space-around;
    width: 75vw;
  }
  #videoCategories.desktopCategory {
    font-size: 25px;
  }

}

@media only screen and (max-width: 930px) {
  #videoCategories {
    padding-top: calc(50px);
  }
  #desktopCategories {
    display: none;
  }
  .mobileHeader {
    display: block;
  }

  .videoThumbnail, .logoContainer {
    width: 96vw;
    min-width: 0;
    max-width: 700px;
    height: 48vw;
    min-height: 0;
    max-height: 350px;
  }

  .videoLogo, .thumbnailText {
    visibility: visible !important;
    opacity: 0.9 !important;
  }
}

#videoCategories {
  font-weight: 400;
  font-size: 30px;
}

.videoLogo {
  position: relative;
  width: 30%;
  height: 100%;

  z-index: 5;
  visibility: hidden;
  opacity: 0;

  transition: visibility 100ms, opacity 100ms;
  transition-timing-function: ease-in-out;
  pointer-events:none;
}

.thumbnailText {
  color: white;
  font-weight: 500;
  font-size: calc(20px);

  z-index: 5;
  padding-top: 15%;

  visibility: hidden;
  opacity: 0;
  transition: vility 100ms, opacity 100ms;
  transition-timing-function: ease-in-out;
  pointer-events:none;
}

.videoCard {
  margin: 7px;
}

.videoCard:hover > .logoContainer > div > div > .videoLogo, .videoCard:hover > .logoContainer > div > div > .thumbnailText {
  visibility: visible;
  opacity: 0.9;
}
.videoCard:hover > .logoContainer > div > .videoLogo, .videoCard:hover > .logoContainer > div > .thumbnailText {
  visibility: visible;
  opacity: 0.9;
}

#aboutSection {
  width: 100%;
  background-color: #141414;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 50px;
}

#aboutWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding-left: 15px;
  padding-right: 15px;
}

#aboutHeader {
  padding-bottom: 15px;
  font-weight: 400;
}

#aboutHeadshot {
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.16), 0 3px 3px rgba(0,0,0,0.23);
  height: 335px;
  max-height: 50vw;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 15px;
  /*margin-top: calc(101px + 0.5vw);*/
}

#aboutContact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  margin-top: 50px;
}

.aboutContact {
  color: grey;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.aboutContactIcons {
  padding-top: 11px !important;
}

.contactInfo.aboutContact:hover {
  color: white;
}

.contactIcon:hover > svg > path, a:hover > svg > rect {
  fill: white;
}

/* MENU ICON */
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, stroke 400ms;
  stroke: #fff;
  stroke-width:4.5;
  stroke-linecap:round;
}
.line.active {
  stroke:#000;
}
.ham1 .top {
  stroke-dasharray: 40 139;
}
.ham1 .bottom {
  stroke-dasharray: 40 180;
}
.ham1.active .top {
  stroke-dashoffset: -98px;
}
.ham1.active .bottom {
  stroke-dashoffset: -138px;
}
